.dot {
  color: var(--accent);
}

.h1 {
  font-size: 150pt;
  color: inherit;
  font-family: "Montserrat";
}

.hero-section {
  min-height: 100vh;
}

.hero-text p {
  font-size: 30pt;
}

.hero-left {
  z-index: 1;
}

.chevron-down {
  position: absolute;
  bottom: 3%;
  left: 50%;
  right: auto;
}

.chevron-icon {
  position: relative;
  left: -50%;
  color: #36382e !important;
}

.contact-link:hover {
  color: var(--accent);
}

@media only screen and (max-width: 992px) {
  .chevron-down {
    display: none;
  }

  .h1 {
    max-width: 100%;
    text-align: center;
  }

  .hero-paragraph {
    max-width: 100%;
    text-align: center;
    margin-bottom: 100px;
  }

  .hero-img {
    width: 100%;
    height: auto;
  }
}
