.h3 {
  font-family: "Montserrat";
  color: inherit;
}

.about {
  font-weight: 550 !important;
}

@media only screen and (max-width: 992px) {
  .h3 {
    max-width: 100%;
    text-align: center;
    margin-top: 100px;
  }

  #about {
    padding-top: 80px;
  }
}
