.navbar {
  position: fixed;
  width: 100vw;
  height: 100px;
  transition: all 200ms ease-in-out;
}

.navbar-collapse {
  height: 80px;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.nav-link {
  color: var(--text) !important;
}

.burger-link {
  color: #f8f8f8 !important;
}

.nav-active {
  visibility: visible;
}

.nav-hidden {
  visibility: hidden;
  transition: all 200ms ease-out;
  transform: translate(0, -100%);
}

@media only screen and (max-width: 992px) {
  .desktop-nav-item {
    display: none;
  }
}
