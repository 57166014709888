@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf")
    format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  src: url("./fonts/Source_Sans_Pro/SourceSansPro-Light.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

:root {
  --text: rgb(37, 50, 55);
  --accent: #aa96ed;
  --wineboard: #93024e;
}

body {
  background-color: #f8f8f8;
  color: var(--text);
  overflow-x: hidden !important;
}

#root {
  position: relative;
  overflow-x: hidden !important;
}

.preloader {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  transition: opacity 350ms ease-in-out;
}

.op-0 {
  opacity: 0;
}

/* ---------- BUTTONS ---------- */

.btn-custom {
  display: -webkit-box;
  display: flex;
  max-height: 50px;
  -webkit-box-align: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
}

.btn-custom a {
  width: 100%;
  max-width: 240px;
  height: 54px;
  padding: 8px;
  font-size: 0.8rem;
  font-weight: 900;
  color: var(--wineboard);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 0 0 1px inset rgba(236, 232, 225, 0.3);
  position: relative;
  margin: 10px 0;
}

.btn-custom a.white:hover > p {
  color: #ece8e1;
}

.btn-custom a.white > p {
  background: #ece8e1;
  color: #0f1923;
}

.btn-custom a.white > p span.base {
  border: 1px solid transparent;
}

.btn-custom a.transparent:hover > p {
  color: #ece8e1;
}

.btn-custom a.transparent:hover > p span.text {
  box-shadow: 0 0 0 1px #ece8e1;
}

.btn-custom a.transparent > p {
  background: #0f1923;
  color: #ece8e1;
}

.btn-custom a.transparent > p span.base {
  border: 1px solid #ece8e1;
}

.btn-custom a:after,
.btn-custom a:before {
  content: "";
  width: 1px;
  position: absolute;
  height: 8px;
  background: #0f1923;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-custom a:before {
  right: 0;
  left: initial;
}

.btn-custom a p {
  margin: 0;
  height: 54px;
  line-height: 54px;
  box-sizing: border-box;
  z-index: 1;
  left: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.btn-custom a p span.base {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  border: 1px solid;
}

.btn-custom a p span.base:before {
  content: "";
  width: 2px;
  height: 2px;
  left: -1px;
  top: -1px;
  background: #0f1923;
  position: absolute;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
}

.btn-custom a p span.bg {
  left: -5%;
  position: absolute;
  background: var(--wineboard);
  width: 0;
  height: 100%;
  z-index: 3;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg);
}

.btn-custom a p span.text {
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.btn-custom a p span.text:after {
  content: "";
  width: 4px;
  height: 4px;
  right: 0;
  bottom: 0;
  background: #0f1923;
  position: absolute;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  z-index: 5;
}

.btn-custom a:hover {
  color: #ece8e1;
}

.btn-custom a:hover span.bg {
  width: 110%;
}

.btn-custom a:hover span.text:after {
  background: #ece8e1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}

p {
  font-family: "Source Sans Pro", sans-serif;
  color: inherit;
  font-size: 24pt;
  font-weight: 300;
}

.mobile-only {
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .mobile-only {
    display: flex !important;
  }
}
