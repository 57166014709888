.h3,
.h2 {
  font-family: "Montserrat";
  color: inherit;
}

.timeline-item {
  padding: 3em 2em 2em;
  position: relative;
  color: rgba(black, 0.7);
  border-left: 2px solid var(--accent);
}

.timeline-item p {
  font-size: 1.2rem;
}

.timeline-item::before {
  content: attr(date-is);
  position: absolute;
  left: 2em;
  font-weight: bold;
  top: 1em;
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.785rem;
}

.timeline-item::after {
  width: 10px;
  height: 10px;
  display: block;
  top: 1em;
  position: absolute;
  left: -7px;
  border-radius: 10px;
  content: "";
  border: 2px solid var(--accent-color);
  background: white;
}

.timeline-item:last-child {
  border-image: linear-gradient(to bottom, #aa96ed 60%, rgba(255, 255, 255, 0))
    1 100%;
}
